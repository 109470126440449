import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React, { useCallback, useRef, useState } from 'react';
import type { FC, MouseEvent } from 'react';

import Drawer from 'core/components/Drawer';
import Menu, { MenuOrigin } from 'core/components/Menu';
import MenuDivider from 'core/components/MenuDivider';
import MenuItem from 'core/components/MenuItem';
import Spinner from 'core/components/Spinner';
import MenuIcon from 'core/icons/MenuIcon';

//import NavBarSearchInput from 'components/NavBarSearchInput';
import UserDrawerContent from 'components/UserDrawerContent';
import { SITE_NAME } from 'config';
// import useBadges from 'hooks/data/account/useBadges';
import useCreator from 'hooks/data/account/useCreator';
import useUser from 'hooks/data/account/useUser';
import {
  accountCreatorRoute,
  accountRoute,
  adminRoute,
  faqRoute,
  forumsRoute,
  indexRoute,
  loginRoute,
  logoutRoute,
  profileRoute,
  searchRoute,
  signupRoute,
} from 'routes';

import s from './NavBar.module.scss';

const NavBar: FC = () => {
  const userDrawerAnchorRef = useRef();
  const userMenuAnchorRef = useRef();
  const [userDrawerIsOpen, setUserDrawerIsOpen] = useState(false);
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);

  // const { data: badgesData } = useAccountBadges();
  // const { data: creatorData } = useCreator();
  const { data: userData } = useUser();

  const openUserDrawerLinkClicked = (event: MouseEvent) => {
    event.preventDefault();
    setUserDrawerIsOpen(true);
  };

  const onUserDrawerClosed = useCallback(() => {
    setUserDrawerIsOpen(false);
  }, []);

  const toggleUserMenuLinkClicked = (event: MouseEvent) => {
    event.preventDefault();
    setUserMenuIsOpen(!userMenuIsOpen);
  };

  const onUserMenuClosed = () => {
    setUserMenuIsOpen(false);
  };

  return (
    <header className={cn(s.root, !userData && s.waitingForSession)}>
      <Drawer
        anchorRef={userDrawerAnchorRef}
        isOpen={userDrawerIsOpen}
        onClose={onUserDrawerClosed}>
        <UserDrawerContent />
      </Drawer>

      <div className={s.logo}>
        <>
          <a
            ref={userDrawerAnchorRef}
            href="#"
            onClick={openUserDrawerLinkClicked}
            className={cn(s.menuLink)}>
            <div className={s.icon}>
              <MenuIcon className={s.svgIcon} />
            </div>
          </a>

          <Link href={indexRoute()}>
            <a className={s.logoLink}>{SITE_NAME}</a>
          </Link>
        </>
      </div>
      {/*<div className={s.search}><NavBarSearchInput /></div>*/}
      <div className={s.flex} />
      <div className={s.links}>
        <Link href={searchRoute()}>
          <a className={s.textLink}>Explore</a>
        </Link>
        <Link href={forumsRoute()}>
          <a className={s.textLink}>Forums</a>
        </Link>
        {!userData?.user?.isCreator && !userData?.user?.isCreatorApplicant && (
          <Link href={accountCreatorRoute()}>
            <a className={s.textLink}>Add your profile</a>
          </Link>
        )}
        <Link href={faqRoute()}>
          <a className={s.textLink}>FAQ</a>
        </Link>
      </div>
      <div className={s.icons}>
        {userData && (
          <>
            {userData.user ? (
              <>
                <a
                  className={s.user}
                  ref={userMenuAnchorRef}
                  href="#"
                  onClick={toggleUserMenuLinkClicked}>
                  <div
                    className={s.profilePicture}
                    aria-controls="user-menu"
                    aria-haspopup="true">
                    <Image
                      alt="Profile Picture"
                      height="24"
                      priority={true}
                      src={userData.user.profilePictureUrl}
                      width="24"
                    />
                  </div>
                </a>
                <Menu
                  id="user-menu"
                  anchorRef={userMenuAnchorRef}
                  isOpen={userMenuIsOpen}
                  onClose={onUserMenuClosed}
                  anchorOrigin={{ x: MenuOrigin.Right, y: MenuOrigin.Top }}
                  transformOrigin={{ x: MenuOrigin.Right, y: MenuOrigin.Top }}
                  transformMargin={{ x: 23, y: 23 }}>
                  <>
                    <MenuItem
                      title="Profile"
                      href={profileRoute(userData.user.username)}
                    />
                    <MenuItem title="Account" href={accountRoute()} />
                    {userData.user.isStaff && (
                      <>
                        <MenuDivider />
                        <MenuItem title="Admin" href={adminRoute()} />
                      </>
                    )}
                    <MenuDivider />
                    <MenuItem title="Logout" href={logoutRoute()} />
                  </>
                </Menu>
              </>
            ) : (
              <>
                <Link href={loginRoute()}>
                  <a className={cn(s.link, s.textLink)}>Login</a>
                </Link>
                <Link href={signupRoute()}>
                  <a className={cn(s.link, s.textLink)}>Sign up</a>
                </Link>
              </>
            )}
          </>
        )}
      </div>
      <div className={s.visuallyHidden}>
        <Link href={loginRoute()}>
          <a className={cn(s.link, s.textLink)}>Login</a>
        </Link>
        <Link href={signupRoute()}>
          <a className={cn(s.link, s.textLink)}>Sign up</a>
        </Link>
      </div>
      {!userData && (
        <div className={s.loading}>
          <Spinner classNames={{ root: s.loadingColor }} />
        </div>
      )}
    </header>
  );
};

export default NavBar;
