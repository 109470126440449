import React from 'react';
import type { FC } from 'react';

import SvgIcon from '../components/SvgIcon';
import type { IconProps } from '../components/SvgIcon';

const ForumsIcon: FC<IconProps> = ({ className, color }) => (
  <SvgIcon>
    <path
      fill={color}
      className={className}
      d="M21.4970156,1.09139364e-14 C23.1927347,1.09139364e-14 24.0270629,0.79903125 24,2.39709375 L24,16.8831563 C24.0270629,18.4277188 23.1927347,19.2 21.4970156,19.2 L4.78692188,19.2 L-1.85536919e-13,24 L-1.85536919e-13,2.55513281 C-1.85536919e-13,0.851710938 0.806,1.09139364e-14 2.418,1.09139364e-14 L21.4970156,1.09139364e-14 Z M14.4,11.904 L4.8,11.904 L4.8,14.304 L14.4,14.304 L14.4,11.904 Z M19.2,8.352 L4.8,8.352 L4.8,10.752 L19.2,10.752 L19.2,8.352 Z M19.2,4.8 L4.8,4.8 L4.8,7.2 L19.2,7.2 L19.2,4.8 Z"
    />
  </SvgIcon>
);

export default ForumsIcon;
